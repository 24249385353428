<template>
  <div id="order-view-action-request-invoice">
    <b-button id="order-view-action-request-invoice-btn" v-ripple.400 class="mb-75" block @click.prevent="invoiceRequestEdition = true">
      <feather-icon icon="FileTextIcon" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ $t('action.request_an_invoice') }} </span>
    </b-button>

    <app-sidebar-form
      form-name="order-view-action-request-invoice"
      :edition="invoiceRequestEdition"
      edition-mode-title="edit"
      :title="$t('action.request_an_invoice')"
      :entity="invoiceRequest"
      :submit-btn-text="$t('action.request_an_invoice')"
      submit-btn-icon="FileTextIcon"
      submit-btn-variant="primary"
      @update:edition="invoiceRequestEdition = $event"
      @update:entity="requestAnInvoice($event)"
    >
      <template #fields="data">
        <b-row>
          <b-col cols="12">
            <!-- subject-->
            <validation-provider
              #default="{ errors }"
              :name="$t('offer.max_subscription')"
              rules="required"
              vid="order-view-action-request-invoice-subject-provider"
            >
              <b-form-group :label="$t('common.subject')" label-for="order-view-action-request-invoice-subject">
                <b-form-input
                  id="order-view-action-request-invoice-subject"
                  v-model="data.item.subject"
                  type="text"
                  :placeholder="$t('common.subject')"
                  :state="errors[0] ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <!-- message -->
            <validation-provider #default="{ errors }" :name="$t('common.message')" rules="" vid="order-view-action-request-invoice-message-provider">
              <b-form-group :label="$t('common.message')" label-for="order-view-action-request-invoice-message">
                <b-form-textarea
                  id="order-view-action-request-invoice-message"
                  v-model="data.item.message"
                  :placeholder="$t('common.message')"
                  no-resize
                  rows="4"
                  class="hide-scroll"
                  max-rows="4"
                  :state="errors[0] ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- TODO file url -->
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
// AWAIT API fix
import AppSidebarForm from '@/components/AppSidebarForm.vue'

import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import { patchQuoteRequestInvoiceRequest } from '@/request/globalApi/requests/quoteRequests'

export default {
  name: 'OrderViewActionRequestInvoice',

  components: {
    AppSidebarForm,
  },

  data() {
    return {
      invoiceRequestEdition: false,
      invoiceRequest: { subject: '', message: '', filesUrls: [] },
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected']),
    ...mapFields('quote', ['quoteLoading']),
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),

    requestAnInvoice(invoiceRequest) {
      this.quoteLoading = true
      patchQuoteRequestInvoiceRequest(this.quoteSelected.quote.id, invoiceRequest, this.$store.getters['quote/quoteTitleAndId'])
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteLoading = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.hide-scroll::-webkit-scrollbar {
  display: none;
}
</style>
